import Vue from 'vue'
import Vuex from 'vuex'
import getters from './getters'
import user from './modules/user'
import menu from './modules/menu'
import pageConfig from './modules/pageConfig'
import tabsView from './modules/tabsView'
Vue.use(Vuex)

export default new Vuex.Store({
  getters,
  modules: {
    user,
    menu,
    pageConfig,
    tabsView
  },
})
