const Types = {
  ADD_VISITED_VIEWS: 'ADD_VISITED_VIEWS',
  DEL_VISITED_VIEWS: 'DEL_VISITED_VIEWS',
  DEL_OTHERS_VIEWS: 'DEL_OTHERS_VIEWS',
  DEL_ALL_VIEWS: 'DEL_ALL_VIEWS',
  SET_TAGS_SHADOW: 'SET_TAGS_SHADOW',
  SET_CURRENT_TAB: 'SET_CURRENT_TAB'
}


const state = {
  visitedViews: [], // 所有正在游览的页面,不包括defaultViews
  cachedViews: [],
  defaultViews: [
    // 数据格式
    {
      path: "home",
      name: 'home', // tab对应的router name
      query: {},
      meta: {
        label: "首页",
        cache: true,
        closable: false,
        shareable: true
      }
    }
  ],
  tabsIndex: 0, // 关闭单个标签时，打开临近的左侧右侧标签定位。
  tabsShadow: false, // 多标签tag 阴影
}

const actions = {// 设置多标签tag 滚动阴影
  setTabsShadow({ commit }, params) {
    commit(Types.SET_TAGS_SHADOW, params)
  },
  //根据当前路由设置打开标签
  setCurrentTab({ commit }, currentTab) {
    commit(Types.SET_CURRENT_TAB, currentTab)
  },
  //添加标签
  addVisitedView({ commit }, view) {
    commit(Types.ADD_VISITED_VIEWS, view)
  },
  //关闭当前标签
  delVisitedView({ commit, state }, view) {
    return new Promise(resolve => {
      commit(Types.DEL_VISITED_VIEWS, view)
      resolve([...state.defaultViews, ...state.visitedViews])
    })
  },
  //关闭其他标签
  delOthersViews({ commit, state }, view) {
    return new Promise((resolve) => {
      commit(Types.DEL_OTHERS_VIEWS, view)
      resolve()
    })
  },
  //关闭全部标签
  delAllViews({ commit }) {
    return new Promise(resolve => {
      commit(Types.DEL_ALL_VIEWS)
      resolve()
    })
  },
}

const mutations = {
  SET_CURRENT_TAB: (state, currentTab) => {
    state.currentTab = currentTab
  },
  ADD_VISITED_VIEWS: (state, view) => {
    // defaultViews不添加
    if (
      state.defaultViews.some(v => {
        /* //工作台v.base 基础复用模块
        if (v.base && v.base.includes(view.name)) {
          v.name = view.name
          v.meta.label = view.meta.label
          return true
        } else {
          return v.name === view.name
        } */
        return v.name === view.name
      })
    ) {
      return
    }
    let label = view.meta.label

    for (const v of state.visitedViews) {
      if (view.meta && view.meta.shareable) {
        // query,params不一致，共享标签
        if (v.name === view.name) {
          // 覆盖原先的 query,params 。
          v.query = view.query
          v.params = view.params
          // 标签共享，修改label需要覆盖原来的label
          v.meta.label = view.meta.label
          return
        }
      } else {
        // query,params不一致。多个标签
        if (
          v.name === view.name &&
          JSON.stringify(v.params) === JSON.stringify(view.params) &&
          JSON.stringify(v.query) === JSON.stringify(view.query)
        ) {
          return
        }
      }
    }
    const newView = {
      query: view.query,
      params: view.params,
      name: view.name,
      meta: {
        label: label,
        closable: view.meta.closable,
        shareable: view.meta.shareable,
        cache: view.meta.cache,
      }
    }

    state.visitedViews.push(newView)

    // 添加cachedViews
    if (view.meta.cache) {
      const cachedViews = [...state.cachedViews]
      cachedViews.push(view.name)
      state.cachedViews = Array.from(new Set([...cachedViews]))
    }
  },
  DEL_VISITED_VIEWS: (state, view) => {
    if (!view) {
      return
    }
    for (const [i, v] of state.visitedViews.entries()) {
      if (
        v.name === view.name &&
        JSON.stringify(v.params) === JSON.stringify(view.params) &&
        JSON.stringify(v.query) === JSON.stringify(view.query)
      ) {
        state.visitedViews.splice(i, 1)
        const defaultLength = state.defaultViews.length
        state.tabsIndex = state.visitedViews.length === i ? defaultLength + i - 1 : defaultLength + i
        break
      }
    }

    for (const i of state.cachedViews) {
      if (i === view.name) {
        const index = state.cachedViews.indexOf(i)
        state.cachedViews.splice(index, 1)
        break
      }
    }
  },
  DEL_OTHERS_VIEWS: (state, view) => {
    const visitedArr = []
    const cachedArr = []
    for (const v of state.visitedViews) {
      if (
        !v.meta.closable ||
        (v.name === view.name &&
          JSON.stringify(v.params) === JSON.stringify(view.params) &&
          JSON.stringify(v.query) === JSON.stringify(view.query))
      ) {
        visitedArr.push(v)
        if (v.meta.cache) {
          cachedArr.push(v.name)
        }
      }
    }
    state.visitedViews = visitedArr
    state.cachedViews = cachedArr
  },
  DEL_ALL_VIEWS: (state) => {
    const visitedArr = []
    const cachedArr = []
    for (const v of state.visitedViews) {
      // 不可关闭的
      if (!v.meta.closable) {
        visitedArr.push(v)
        if (v.meta.cache) {
          cachedArr.push(v.name)
        }
      }
    }
    state.visitedViews = visitedArr
    state.cachedViews = cachedArr
  },
  SET_TAGS_SHADOW(state, params) {
    state.tabsShadow = params
  }
}

export default {
  namespaced: true,
  state,
  actions,
  mutations
}