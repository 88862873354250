<template>
  <el-menu
    ref="menu"
    mode="vertical"
    class="el-menu-vertical c-menu"
    :collapse="isCollapse"
    :unique-opened="uniqueOpened"
    :default-active="defaultActive"
    :default-openeds="defaultOpeneds"
  >
    <template v-for="(item, i) in menus">
      <template v-if="item.children && item.children.length > 0">
        <el-submenu :index="item.menuKey" popper-class="c-menu--popover">
          <template slot="title">
            <i :class="item.icon"></i>
            <span slot="title">{{ item.menuName }}</span>
            <el-tooltip effect="dark" :content="item.label" placement="right">
              <span v-if="item.label.length > 6">{{ item.menuName }}</span>
            </el-tooltip>
          </template>
          <sub-menu
            v-for="(subMenu, i) in item.children"
            :key="subMenu.path + i"
            :menu-item="subMenu"
            :index="subMenu.name"
          />
        </el-submenu>
      </template>
      <template v-else>
        <el-menu-item
          v-if="!item.hidden"
          :index="item.menuKey"
          @click.native="executeRouter(item)"
        >
          <i :class="item.icon"></i>
          <span slot="title">
            <el-tooltip
              :disabled="item.label.length < 7"
              effect="dark"
              :content="item.label"
              placement="right"
            >
              <span>{{ item.menuName }}</span>
            </el-tooltip>
          </span>
        </el-menu-item>
      </template>
    </template>
  </el-menu>
</template>
<script>
import { mapGetters, mapState, mapActions } from "vuex";
import SubMenu from "./SubMenu.vue";
import { menus } from "@/router/menus";
export default {
  components: { SubMenu },
  data() {
    return {
      defaultActive: "", // 默认激活的导航
      defaultOpeneds: [], // 默认打开的菜单
      menus: [],
      uniqueOpened: true, // 是否允许只能打开一个菜单
    };
  },
  computed: {
    // 侧边栏是否默认收起
    ...mapGetters(["isCollapse"]),
  },
  // 根据路由变化-左侧默认高亮菜单
  watch: {
    $route: {
      handler: function (val) {
        this.defaultActive = this.$route.name;
        // console.log(this.defaultActive);
        // menuKey ===
        // const route = {
        //   ...this.$route,
        //   meta: {
        //     label: this.$route.meta.label,
        //     cache: this.$route.meta.cache,
        //     closable: true,
        //   },
        // };
        // this.addVisitedView(route);
      },
      immediate: true,
    },
  },
  created() {
    this.$store.dispatch("getMenu").then((res) => {
      this.menus = res;
    });
  },
  methods: {
    ...mapActions({
      addVisitedView: "tabsView/addVisitedView",
      getMenu: "menu/getMenu",
    }),
    executeRouter(menu) {
      //添加tabs
      this.$router.push({ name: menu.menuKey });
    },
  },
};
</script>