//注册全局组件
// 自定义全局组件
import Vue from 'vue'
const requireComponent = require.context(
	// 其组件目录的相对路径
	'../components',
	// 是否查询其子目录
	true,
	// 匹配vue后缀文件名的文件
	/.vue$/
)

//需要全局注册form-items下的组件
requireComponent.keys().forEach((fileName) => {
	let name = fileName.split('/')
	name = name[name.length - 1].split('.')[0]
	const componentConfig = requireComponent(fileName)
	const VM = componentConfig.default || componentConfig
	Vue.component(VM.name || name, VM)
})