import UserInfo from "@/layout/components/mock/userinfo.json"
import { cloneDeep, reject } from "lodash"
import { login, logout } from "@/api/index"
import Utils from "@/utils/utils"

const Types = {
  SET_USER_INFO: 'SET_USER_INFO',
  SET_NAV_CUR: 'SET_NAV_CUR',
  SET_MENU_CUR: 'SET_MENU_CUR',
  SET_PERMISSIONS: 'SET_PERMISSIONS',
  SET_MENU_GW_DISPLAY: 'SET_MENU_GW_DISPLAY'
}

const user = {
  state: {
    userInfo: {
      // userId: '',
      // avatar: '',
      // certLevel: '',
      // deptCode: '',
      // deptId: '',
      // deptName: '',
      // fullname: '',
      // moodMessage: '',
      // nickname: '',
      // nodeCode: '',
      // nodeId: '',
      // nodeName: '',
      // openid: '',
      // mobilePhone: '',
      // permissions: [],//后端按钮权限位
      // menus: {},
      // initMenus: []
      "id": '',
      "phone": "",
      "email": null,
      "img": null,
      "password": "",
      "roleId": null,
      "name": "",
      "status": "",
      "area": null,
      "deleteFlag": 0
    },
  },
  actions: {
    getLoginInfo({ commit }, userInfo) {

      return new Promise(async (resolve, reject) => {
        const res = await login(userInfo)
        if (res.status) {
          //提交用户信息
          commit(Types.SET_USER_INFO, res.data)
          resolve(res.data)
        }
      })

      // //提交权限信息
      // commit(Types.SET_PERMISSIONS, UserInfo.permissions)
      // //处理菜单
      // let menus = cloneDeep(UserInfo.menus)
      // //设置菜单
      // commit(Types.SET_MENU, menus)
    },
    clearUserInfo({ commit }) {
      return new Promise(async (resolve, reject) => {
        const res = await logout()
        if (res.status) {
          //提交用户信息
          commit(Types.SET_USER_INFO, res.data)
          resolve(res.data)
        }
      })
    }
  },
  mutations: {
    SET_USER_INFO: (state, params) => {
      // state.userInfo = {
      //   ...params,
      //   permissions: [],
      //   initMenus: params.menus,
      //   menus: {}
      // }
      state.userInfo = { ...params }
      Utils.setStorage('userInfo', params)
    },
    //设置权限
    SET_PERMISSIONS: (state, params) => {
      state.userInfo.permissions = params
    },

  }
}

export default user
