<template>
  <div class="c-sidebar" :class="{ 'c-sidebar--collapse': isCollapse, 'c-sidebar--nav': navOpen }">
    <div class="c-logo" :class="isCollapse ? 'c-header-nav__logo_collapse' : 'c-header-nav__logo '
      ">
      <el-image
        :src="logo"
        class="c-header-nav__img"
        :fit="isCollapse ? 'contain' : 'cover'"
      ></el-image>
      <span v-if="!isCollapse">红心到家</span>
    </div>
    <div v-if="!navOpen" class="c-sidebar__header u-flex-center">
      <!-- <c-icon
        v-if="!isCollapse"
        icon-class="logo_gongzuotai"
        class-name="c-sidebar__img"
      ></c-icon>
      <c-icon v-else icon-class="branch-1"></c-icon> -->
      <i v-if="!isCollapse" class="el-icon-back"></i>
      <i v-else class="el-icon-right"></i>
    </div>
    <div class="c-sidebar__body">
      <Menu></Menu>
    </div>
    <!-- <div class="c-sidebar__footer u-flex-center" @click="toggleSideBar">
      <i :class="isCollapse ? 'el-icon-right' : 'el-icon-back'"></i>
    </div> -->
  </div>
</template>
<script>
import { mapGetters, mapState, mapActions } from "vuex"
import Menu from "./modules/menu/Menu.vue"
import logo from "@/assets/logo2.png"
export default {
  components: { Menu },
  data() {
    return {
      logo,
    }
  },
  computed: {
    // 侧边栏是否默认收起
    ...mapGetters(["isCollapse"]),
    ...mapState({
      navOpen: (state) => state.pageConfig.navOpen,
    }),
  },
  methods: {
    ...mapActions({
      toggleSideBar: "pageConfig/toggleSideBar",
    }),
  },
}
</script> 
<style lang="scss" >
@import "@/styles/index.scss";

.c-sidebar {
  ::-webkit-scrollbar {
    position: absolute;
    width: 10px;
    margin-left: -10px;
    -webkit-appearance: none;
  }

  ::-webkit-scrollbar-thumb {
    height: 50px;
    background-clip: content-box;
    border-color: transparent;
    border-style: solid;
    border-width: 1px 2px;
  }

  .u-flex-center {
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

.c-logo {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.c-header-nav__logo_collapse {
  width: 70px;
  height: $--height-header;
  font-size: 21px;
  font-weight: 550;
  position: relative;
  color: var(--color-primary);
  // color: #fff;
  background-color: $--color-sidebar;
  border-bottom: 1px solid rgba(0, 0, 0, 0.12);
  padding: 0 10px;
  display: flex;
  justify-content: space-between;

  .c-header-nav__img {
    height: 80%;
  }
}

.c-header-nav__logo {
  width: $--width-sidebar;
  height: $--height-header;
  font-size: 21px;
  font-weight: 550;
  position: relative;
  //color: var(--color-primary);
   color: #fff;
  background-color: $--color-sidebar;
  border-bottom: 1px solid rgba(0, 0, 0, 0.12);
  padding: 0 20px;
  display: flex;
  justify-content: space-evenly;

  .c-header-nav__img {
    height: 60%;
  }
}
</style>