<template>
  <el-dialog
    :title="title"
    :visible.sync="dialogVisible"
    width="30%"
    :before-close="handleClose"
  >
    <slot name="body"></slot>
    <span slot="footer" class="dialog-footer">
      <el-button size="mini" @click="dialogVisible = false">取 消</el-button>
      <el-button size="mini" type="primary" @click="handleSubmit">确 定</el-button>
    </span>
  </el-dialog>
</template>
<script>
export default {
  name: "CommonDialog",
  props: {
    //弹框标题
    title: {
      type: String,
      default: "提示",
    },
    //点确定时是否添加校验
    validForm: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      dialogVisible: false,
    };
  },
  methods: {
    show() {
      this.dialogVisible = true;
    },
    handleClose() {
      this.dialogVisible = false;
    },
    handleSubmit() {
      this.$emit("submit", (data) => {
        console.log(data);
      });
      // if (submitFlag) {
      //   this.dialogVisible = false;
      // }
    },
  },
};
</script>