<template>
  <tab-menu
    class="tab-menu"
    :class="classObj"
    :panes="getVisitedViews"
    :current-name="currentName"
    :current-params="currentParams"
    :current-query="currentQuery"
    @close-other="closeOther"
    @close-all="closeAll"
    @tab-remove="removeTab"
    @tab-click="clickTab"
  ></tab-menu>
</template>
<script>
import { mapState, mapGetters, mapActions } from "vuex";
import TabMenu from "./modules/tabs/TabMenu.vue";
import GLOBAL from "@/configs/global";
export default {
  name: "CTabs",
  components: { TabMenu },
  data() {
    return {
      currentName: "",
      currentParams: {},
      currentQuery: {},
    };
  },
  watch: {
    $route: {
      handler: function () {
        if (this.$route.name && !this.$route.meta.noTab) {
          if (
            this.getVisitedViews.length === GLOBAL.maxTabs &&
            !this.getVisitedViews.some((item) => {
              return item.name === this.$route.name;
            })
          ) {
            this.$message({
              message: `您已经打开了${GLOBAL.maxTabs}个页签，打开过多页签可能影响系统性能，请考虑关闭不使用的页签。`,
              type: "warning",
              showClose: true,
            });
          }
          const route = {
            ...this.$route,
          };

          this.addVisitedView(route);
          this.setCurrentTab(route);
          //设置当前路由
          this.currentName = this.$route.name;
          this.currentParams = this.$route.params;
          this.currentQuery = this.$route.query;
        }
      },
      immediate: true,
    },
  },
  computed: {
    ...mapState({
      tabsShadow: (state) => state.tabsView.tabsShadow,
      tabsIndex: (state) => state.tabsView.tabsIndex,
    }),
    ...mapGetters(["getVisitedViews"]), // 总浏览页面
    classObj() {
      return {
        "tab-menu--shadow": this.tabsShadow,
      };
    },
  },
  methods: {
    ...mapActions({
      addVisitedView: "tabsView/addVisitedView",
      setCurrentTab: "tabsView/setCurrentTab",
      delVisitedView: "tabsView/delVisitedView",
      delOthersViews: "tabsView/delOthersViews",
      delAllViews: "tabsView/delAllViews",
    }),
    async closeOther(tab) {
      this.delOthersViews(tab).then(() => {
        if (
          tab.name !== this.currentName ||
          JSON.stringify(tab.params) !== JSON.stringify(this.currentParams) ||
          JSON.stringify(tab.query) !== JSON.stringify(this.currentQuery)
        )
          this.$router.push({
            name: tab.name,
            params: tab.params,
            query: tab.query,
          });
      });
    },
    async closeAll() {
      this.delAllViews().then(() => {
        this.$router.push("/");
      });
    },
    async removeTab(tab) {
      this.delVisitedView(tab).then((views) => {
        if (views.length === 0) {
          this.$router.push("/");
        } else if (
          !views.find((item) => {
            return (
              item.name === this.currentName &&
              JSON.stringify(item.params) ===
                JSON.stringify(this.currentParams) &&
              JSON.stringify(item.query) === JSON.stringify(this.currentQuery)
            );
          })
        ) {
          const view = views[this.tabsIndex];
          this.currentName = view.name;
          this.currentParams = view.params;
          this.currentQuery = view.query;
          this.$router.push({
            name: view.name,
            params: view.params,
            query: view.query,
          });
        }
      });
    },
    clickTab(tab) {
      if (
        tab.name !== this.currentName ||
        JSON.stringify(tab.params) !== JSON.stringify(this.currentParams) ||
        JSON.stringify(tab.query) !== JSON.stringify(this.currentQuery)
      ) {
        this.$router.push({
          name: tab.name,
          params: tab.params,
          query: tab.query,
        });
      }
    },
  },
};
</script>