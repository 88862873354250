<template>
  <div id="app">
    <router-view />
  </div>
</template>
<script>
export default {
  mounted(){
    const userInfo = window.localStorage.getItem('userInfo')
    this.$store.commit("SET_USER_INFO", userInfo && JSON.parse(userInfo));
  },
}
</script>

<style lang="scss">
@import "@/styles/index.scss";
</style>
