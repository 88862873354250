<template>
  <div class="zui-screw zs-page--stretch zs-page">
    <slot name="body"> </slot>
  </div>
</template>
<script>
export default {
  name: "PageContainer",
  data() {
    return {};
  },
};
</script>
<style lang="scss" >
.zs-page--stretch {
  margin: 8px;
  background: #fff;
  box-sizing: border-box;
  padding: 8px 16px;
  height: calc(100% - 16px);
  border-radius: 4px;
}
.zs-page--flex {
  display: flex;
  flex-direction: column;
}
.zs-page {
  height: 100%;
  overflow-y: auto;
}
</style>