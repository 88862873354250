<template>
  <div >
    <template v-if="menuItem.children && menuItem.children.length > 0">
      <!-- 存在子级 -->
      <el-submenu :index="menuItem.path" popper-class="c-menu--popover">
        <template slot="title">
          <i :class="menuItem.icon"></i>
          <!-- span 隐藏 -->
          <span v-if="menuItem.label.length < 7 && !isCollapse">{{
            menuItem.menuName
          }}</span>
          <el-tooltip effect="dark" :content="menuItem.label" placement="right">
            <span v-if="menuItem.label.length > 6">{{
              menuItem.menuName
            }}</span>
          </el-tooltip>
        </template>
        <sub-menu
          v-for="item in menuItem.children"
          :key="item.name"
          :menu-item="item"
          :index="item.name"
        />
      </el-submenu>
    </template>
    <template v-else>
      <el-menu-item
        :key="menuItem.name"
        :index="menuItem.menuKey"
        class="el-menu--nochildren"
        @click.native="executeRouter(menuItem)"
      >
        <i :class="menuItem.icon"></i>
        <span slot="title">
          <el-tooltip
            :disabled="menuItem.label.length < 7"
            effect="dark"
            :content="menuItem.label"
            placement="right"
          >
            <span>{{ menuItem.menuName }}</span>
          </el-tooltip>
        </span>
      </el-menu-item>
    </template>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
import store from "@/store/index";
export default {
  name: "SubMenu",
  props: {
    menuItem: {
      type: Object,
      default: function () {
        return {};
      },
    },
  },
  data() {
    return {};
  },
  computed: {
    // 侧边栏是否默认收起
    ...mapGetters(["isCollapse"]),
  },
  methods: {
    executeRouter(menu) {
      //添加tabs
      this.$router.push({ name: menu.menuKey });
    },
  },
};
</script>