import store from '@/store/index';

export default {
  //获取localStorage信息
  getStorage(key) {
    let storage = localStorage.getItem(key);
    try {
      if (typeof JSON.parse(storage) === 'object') {
        return JSON.parse(storage ? storage : '{}');
      } else {
        return storage;
      }
    } catch (error) {
      return storage;
    }
  },
  setStorage(key, value) {
    if (typeof value === 'object') {
      localStorage.setItem(key, JSON.stringify(value));
    } else {
      localStorage.setItem(key, value);
    }
  },
  clearStorage(key) {
    localStorage.removeItem(key);
  },
  //表单参数去空
  formatParam(obj) {
    const param = {};
    Object.keys(obj).forEach((key) => {
      if (
        (obj[key] && !Array.isArray(obj[key])) ||
        obj[key] === 0 ||
        obj[key] === '0' ||
        (Array.isArray(obj[key]) && obj[key].length > 0)
      ) {
        param[key] = obj[key];
      }
    });
    return param;
  },
  //树形json转数组
  treeToArray(tree, level = 0, parentId = null, primaryKey) {
    const results = [];
    for (const key in tree) {
      if (key === primaryKey) {
        for (const item of tree[key]) {
          results.push({
            name: item,
            level: level,
            parentId: parentId,
          });
        }
      } else {
        const children = this.treeToArray(
          tree[key],
          level + 1,
          key,
          primaryKey
        );
        results.push({
          name: key,
          level: level,
          parentId: parentId,
        });
        results.push(...children);
      }
    }
    return results;
  },
  //判断权限
  permission(value) {
    const roles = store.getters.permissions;

    if (value && value instanceof Array && value.length > 0) {
      const permissionRoles = value;

      return roles.some((role) => {
        return permissionRoles.includes(role);
      });
    }
  },
  //数组转树型数组
  rebuildTree(data) {
    const result = []; // 存放结果集
    const itemMap = {}; //
    for (const item of data) {
      const id = item.id;
      const pid = item.parentId;

      if (!itemMap[id]) {
        itemMap[id] = {
          children: [],
        };
      }

      itemMap[id] = {
        ...item,
        children: itemMap[id]['children'],
      };

      const treeItem = itemMap[id];

      if (pid === 1) {
        result.push(treeItem);
      } else {
        if (!itemMap[pid]) {
          itemMap[pid] = {
            children: [],
          };
        }
        itemMap[pid].children.push(treeItem);
      }
    }
    return result;
  },
  //数组转树形，最后一层不加children
  rebuildTreeLastNoChildren(items) {
    let result = [];
    let map = {};
    for (let i = 0; i < items.length; i++) {
      let item = items[i];
      map[item.id] = i;
      if (item.parentId !== 0) {
        let parent = items[map[item.parentId]];
        if (parent && !parent.children) {
          parent.children = [];
        }
        parent.children.push(item);
      } else {
        result.push(item);
      }
    }
    return result;
  },
  //压缩图片
  compressImage(file) {
    return new Promise((resolve, reject) => {
      const maxSize = 80 * 1024; // 80kb
      const { size } = file;
      //判断size<80kb直接返回
      if (size < maxSize) {
        resolve(file);
      }
      const reader = new FileReader();

      // 读取文件内容为DataURL格式
      reader.readAsDataURL(file);

      reader.onloadend = function () {
        const img = document.createElement('img');

        // 设置加载完成后的处理函数
        img.onload = () => {
          const canvas = document.createElement('canvas');

          // 根据原始图像大小创建画布
          canvas.width = img.naturalWidth;
          canvas.height = img.naturalHeight;

          const context = canvas.getContext('2d');

          // 将原始图像绘制到画布上
          context.drawImage(img, 0, 0);

          // 根据图片大小计算压缩比，将图片最终生成70-80kb左右大小的图片

          const radio = (size / maxSize).toFixed(2);
          // 获取压缩后的图像数据
          const compressedImgDataUrl = canvas.toDataURL(
            'image/jpeg',
            radio / 10
          );

          resolve({ file: compressedImgDataUrl });
        };

        // 开始加载图像
        img.src = reader.result;
      };

      reader.onerror = (err) => {
        reject(new Error(`Failed to read image file: ${err}`));
      };
    });
  },
};
