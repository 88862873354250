<script>
import TabNav from "./TabNav";

export default {
  //tabs父级 和 鼠标右击菜单
  name: "TabMenu",
  components: {
    TabNav,
  },

  props: {
    panes: {
      type: Array,
      default: function () {
        return [];
      },
    },
    currentName: {
      type: String,
      default: "",
    },
    currentParams: {
      type: Object,
      default: function () {
        return {};
      },
    },
    currentQuery: {
      type: Object,
      default: function () {
        return {};
      },
    },
  },

  data() {
    return {
      clientX: "0px",
      clientY: "0px",
      isShowMenu: false,
      curMouseOverTab: {},
    };
  },

  watch: {
    currentName() {
      this.$nextTick(() => {
        if (this.$refs.nav) this.$refs.nav.scrollToActiveTab();
      });
    },
  },
  created() {
    this.handlerDocClick();
  },
  methods: {
    handlerDocClick() {
      document.addEventListener("click", () => {
        this.isShowMenu = false;
      });
    },
    closeOtherTab() {
      // 向父组件传出关掉其他标签栏的事件 传出为当前操作的标签栏
      this.$emit("close-other", this.curMouseOverTab);
    },
    closeAllTab() {
      // 向父组件传出关掉所有标签栏的事件  传出为当前操作的标签栏
      this.$emit("close-all", this.curMouseOverTab);
    },
    handleTabClick(tab, tabName, event) {
      // this.setCurrentName(tabName);
      this.$emit("tab-click", tab, event);
    },
    handleTabMouseDown(tab, tabName, event) {
      this.curMouseOverTab = tab;
      // 鼠标按下事件传出给父组件的事件
      this.$emit("mouse-down", {
        tab: tab,
        tabName: tabName,
      });
      if (event.button === 2) {
        this.clientX = event.clientX + 10 + "px";
        this.clientY = event.clientY + "px";
        this.isShowMenu = true;
      }
    },
    handleTabRemove(pane, ev) {
      ev.stopPropagation();
      this.$emit("tab-remove", pane);
    },
  },
  render() {
    const {
      handleTabClick,
      handleTabRemove,
      handleTabMouseDown,
      currentName,
      currentParams,
      currentQuery,
      closeOtherTab,
      closeAllTab,
      panes,
      isShowMenu,
      clientX,
      clientY,
    } = this;

    const navData = {
      props: {
        currentName,
        currentParams,
        currentQuery,
        onTabClick: handleTabClick,
        onTabRemove: handleTabRemove,
        onTabMouseDown: handleTabMouseDown,
        panes,
      },
      ref: "nav",
    };
    const showType = isShowMenu ? "block" : "none";
    return (
      <div
        class="el-tabs el-tabs--card"
        on-contextmenu={(ev) => {
          ev.preventDefault();
          ev.stopPropagation();
        }}
      >
        <div class="el-tabs__header">
          <TabNav {...navData}></TabNav>
        </div>
        <div
          style={{ display: showType, left: clientX, top: clientY }}
          class="tab-menu__popover"
        >
          <ul>
            <li class="tab-menu__item" on-click={closeOtherTab}>
              关闭其它标签
            </li>
            <li class="tab-menu__item" on-click={closeAllTab}>
              关闭全部标签
            </li>
          </ul>
        </div>
      </div>
    );
  },
};
</script>
<style lang="scss">
@import "@/styles/variables/colors.scss";

.tab-menu {
  .el-tabs__header {
    margin: 0px;
    border-bottom: none;
    height: 36px;
    padding-top: 4px;
  }
  > .el-tabs__header .el-tabs__nav {
    border: none;
    padding: 0 16px;
  }
  //el-tabs__item
  .el-tabs__item {
    height: 32px;
    padding: 6px 0 6px !important;
    line-height: 20px;
    color: $--color-text-secondary;
  }
  .el-tabs__item.is-active:before {
    content: "";
    width: 10px;
    height: 10px;
    z-index: 99;
    background-image: url("../../../../assets/tab.png");
    background-size: 100%;
    position: absolute;
    bottom: 0;
    left: -10px;
  }
  .el-tabs__item.is-active:after {
    content: "";
    width: 10px;
    height: 10px;
    z-index: 99;
    background-image: url("../../../../assets/tab.png");
    background-size: 100%;
    position: absolute;
    bottom: 0;
    right: -10px;
    transform: rotate(90deg);
  }
  .el-tabs__item.is-active {
    color: var(--color-primary);
  }
  > .el-tabs__header .el-tabs__item {
    border: none;
  }
  > .el-tabs__header .el-tabs__item:hover {
    background: rgba(238, 240, 246, 0.5);
    border-radius: 4px 4px 0px 0px;
  }
  > .el-tabs__header .el-tabs__item.is-active {
    // background: rgba(238, 240, 246, 1);
    background: $--color-base;
    border-radius: 4px 4px 0px 0px;
  }
  > .el-tabs__header .el-tabs__item .el-icon-circle-close {
    font-size: 16px;
    color: $--color-text-secondary;
    cursor: pointer;
    margin-left: 8px;
  }
  > .el-tabs__header .el-tabs__item.is-active .el-icon-circle-close {
    color: var($--color-primary);
  }
  //el-tabs__subitem
  .el-tabs__subitem {
    padding: 0 16px;
  }
  .el-tabs__subitem {
    border-left: 1px solid #e4e7ed;
  }
  .el-tabs__item:first-child .el-tabs__subitem {
    border-left: 1px solid transparent;
  }
  .el-tabs__item.is-active .el-tabs__subitem {
    border-left: 1px solid transparent;
  }
  .el-tabs__item.is-active + .el-tabs__item .el-tabs__subitem {
    border-left: 1px solid transparent;
  }
  .el-tabs__item:hover .el-tabs__subitem {
    border-left: 1px solid transparent;
  }
  .el-tabs__item:hover + .el-tabs__item .el-tabs__subitem {
    border-left: 1px solid transparent;
  }
  .el-tabs__nav-prev,
  .el-tabs__nav-next {
    display: flex;
    height: 32px;
    align-items: center;
  }
  .el-tabs__nav-prev {
    padding: 0 5px 0 15px;
  }

  .el-tabs__nav-next {
    padding: 0 15px 0 5px;
  }

  .el-icon-arrow-left,
  .el-icon-arrow-right {
    border: 1px solid var(--color-primary);
    border-radius: 50%;
    padding: 2px;
    color: var(--color-primary);
    font-size: 12px;
  }

  .el-tabs__nav-wrap.is-scrollable {
    padding: 0 38px;
  }
  .tab-menu__popover {
    ul,
    li {
      list-style: none;
      padding: 0;
      margin: 0;
    }
    position: fixed;
    cursor: pointer;
    background: #ffffff;
    z-index: 10000;
    box-shadow: 0px 2px 4px 2px #eeeeee;
    .tab-menu__item {
      padding: 10px 20px;
      font-size: 14px;
      &:hover {
        background: #f4f3f3;
      }
    }
  }
}
.tab-menu--shadow {
  position: relative;
  z-index: 1000;
  box-shadow: 0 0 10px 0 rgba(123, 123, 123, 1);
}
</style>
