import request from '@/utils/request';
//获取菜单列表
export async function getMenuList(data) {
  return await request.get('/hx/resources/list', data);
}

//获取角色授权菜单
export async function getRoleAuthMenuList(data) {
  return await request.get('/hx/role/getRoleResources', data);
}

//获取用户授权菜单
export async function getLoginResources(data) {
  return await request.get('/hx/role/getLoginResources', data);
}
