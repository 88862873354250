<template>
  <!--二次封装el-select，支持根据form配置设置下拉项等-->
  <el-select
    v-model="inputValue"
    v-on="$listeners"
    v-bind="$attrs"
    class="fill_formItems"
  >
    <el-option
      v-for="(item, index) in options"
      :key="index"
      :label="item[optionLabelName]"
      :value="item[optionValueName]"
    >
    </el-option>
  </el-select>
</template>
<script>
export default {
  name: "HSelect",

  props: {
    value: {
      type: [String, Number, Array],
    },
    options: {
      type: Array,
      default: () => [],
    },
    formData: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {};
  },
  computed: {
    inputValue() {
      return this.formData[this.$attrs.prop];
    },
    optionLabelName() {
      return this.$attrs.optionLabel || "label";
    },
    optionValueName() {
      return this.$attrs.optionValue || "value";
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/styles/components/form-items/select.scss";
</style>
