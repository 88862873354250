<template>
  <div class="type-list">
    <div
      class="type-item"
      @click="changeType(item, index)"
      :class="activeIndex === item.value ? 'el-icon-check el-icon active' : ''"
      v-for="(item, index) in typeList"
      :key="index"
    >
      <div class="title">{{ item.label }}</div>
      <div class="desc">({{ item.desc }})</div>
    </div>
  </div>
</template>
<script>
export default {
  name: "BlockRadio",
  props: {
    typeList: {
      type: Array,
      default: () => [],
    },
    type:{
      type:Number,
      default:0
    }
  },
  watch: {
    type(newVal, oldVal) {
      this.activeIndex = newVal;
      // console.log(newVal, oldVal);
    },
  },
  data() {
    return {
      activeIndex: 0,
    };
  },
  methods: {
    changeType(item, index) {
      this.activeIndex = index;
      this.$emit("changeType", index);
    },
  },
};
</script>
<style lang="scss" scoped>
.type-list {
  display: flex;
  .type-item {
    width: 140px;
    margin-right: 10px;
    height: 70px;
    border: 1px solid #d7dae2;
    border-radius: 4px;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    position: relative;
    .title {
      font-weight: bold;
      line-height: 24px;
    }
    .desc {
      line-height: 24px;
    }

    &.el-icon-check:before {
      position: absolute;
      font-size: 20px;
      color: #fff;
      right: 0;
      bottom: 0;
      z-index: 9;
    }
    &.active {
      border-color: var(--color-primary);
      &::after {
        content: "";
        position: absolute;
        right: 0;
        bottom: 0;
        border: 18px solid var(--color-primary);
        border-top-color: transparent;
        border-left-color: transparent;
      }
      // &::after{
      //   content: "";
      //   width: 5px;
      //   height: 10px;
      //   position: absolute;
      //   right: 4px;
      // }
      // border-color: var(--color-primary);
    }
  }
}
</style>