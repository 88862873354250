import store from '@/store/index'
export default {
  inserted(el, binding) {
    const { value } = binding
    if (value) {
      const permCache = store.getters && store.getters.permissions

      // const hasPermission = value.some(key => {
      //   return permCache[key]
      // })
      const hasPermission = permCache.includes(value)
      if (!hasPermission) {
        el.parentNode && el.parentNode.removeChild(el)
      }
    } else {
      throw new Error(`need roles! Like v-perm="['op_editor']"`)
    }
  }
}
