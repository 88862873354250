<template>
  <div class="error-page">
    <img :src="imgUrl" />
    <div>
      <p class="error-title">{{ errorText }}</p>
      <p class="error-desc">{{ errorDesc }}</p>
      <el-button size="mini" type="primary"  @click="toHome">返回首页</el-button>
    </div>
  </div>
</template>
<script>
export default {
  name: "ErrorPage",
  props: {
    imgUrl: {
      type: String,
      default: "",
    },
    errorText: {
      type: String,
      default: "500",
    },
    errorDesc: {
      type: String,
      default: "服务不可用",
    },
  },
  data() {
    return {};
  },
  methods: {
    toHome() {
      this.$router.push({ name: "home" });
    },
  },
};
</script>
<style lang="scss" scoped>
.error-page {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  height: 100%;
  .error-title {
    font-size: 72px;
    color: #434e59;
    margin-bottom: 24px;
    font-weight: 600;
  }
  .error-desc {
    font-size: 20px;
    color: rgba(0, 0, 0, 0.45);
    margin-bottom: 30px;
  }
}
</style>