const maxTabs = 12 // tab标签的数量限制提示
const primaryColor = "#3bc0c3"
const defaultHdColor = '#ffffff'


const tableWidth = {
  selection: 40,
  number: 60,
  op1: 80,
  op2: 100,
  op3: 140,
  op4: 280,
  op5: 180,
  huge: 270,
  large: 160,
  big: 140,
  medium: 120,
  common: 100,
  small: 80,
  mini: 60
}
export default {

  maxTabs, primaryColor, defaultHdColor, tableWidth
}