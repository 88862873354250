export const constantRouterMap = [{
  path: "/403",
  name: "Error403",
  component: () => import("@/views/Error/403.vue"),
  meta: {
    label: '403错误', // 展示的标题
    shareBase: true,
    closable: true, // 此参数为false不可修改
    cache: true,
  }
},
{
  path: "/404",
  name: "Error404",
  component: () => import("@/views/Error/404.vue"),
  meta: {
    label: '404错误', // 展示的标题
    shareBase: true,
    closable: true, // 此参数为false不可修改
    cache: true,
  }
},
{
  path: "/500",
  name: "Error500",
  component: () => import("@/views/Error/500.vue"),
  meta: {
    label: '500错误', // 展示的标题
    shareBase: true,
    closable: true, // 此参数为false不可修改
    cache: true,
  }
}, {
  path: "/error",
  name: "commonError",
  component: () => import("@/views/Error/Common.vue"),
  meta: {
    label: 'commonError', // 展示的标题
    shareBase: true,
    closable: true, // 此参数为false不可修改
    cache: true,
  }
}]