<template>
  <div class="content__box s-table">
    <div class="content__body">
      <el-table
        ref="table"
        :border="showBorder"
        :data="data"
        :stripe="stripe"
        :height="height || '100%'"
        :max-height="maxHeight"
        tooltip-effect="dark"
        align="center"
        empty-text="暂无数据"
        :highlight-current-row="true"
        @row-click="rowClick"
        @row-dblclick="rowDblclick"
        @selection-change="handleSelectionChange"
        @sort-change="sortChange"
      >
        <el-table-column v-if="showSelection" type="selection" width="55" align="center" :fixed="true">
        </el-table-column>
        <el-table-column :label="indexLabel" v-if="showIndex" type="index" width="50" align="center"> </el-table-column>
        <el-table-column
          :sortable="item.sortable || false"
          v-for="(item, index) in columns"
          :type="item.type || 'normal'"
          :key="index"
          :prop="item.prop"
          :label="item.label"
          :width="String(item.width)"
          :min-width="String(item.minWidth)"
          :show-overflow-tooltip="item.showOverflowTooltip || false"
          :align="item.align || 'center'"
          :fixed="item.fixed || false"
          :formatter="item.formatter || null"
        >
          <template slot-scope="scope">
            <slot v-if="item.showSlot" :name="`${item.prop}`" :scope="scope"> </slot>
            <template v-else>{{ scope.row[item.prop] }}</template>
          </template>

          <template slot="header" slot-scope="scope">
            <slot v-if="item.slotHeader" :name="`${item.prop}Header`" :scope="scope"></slot>
            <template v-else>{{ item.label }}</template>
          </template>
        </el-table-column>

        <el-table-column
          :width="String(operatorWidth)"
          v-if="showOperator"
          label="操作"
          label-width="80px"
          align="center"
          :fixed="fixedOperator ? 'right' : false"
        >
          <template slot-scope="scope">
            <slot name="default" :scope="scope"></slot>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <div class="content__footer" v-if="showPager">
      <slot name="pager"></slot>
    </div>
  </div>
</template>
<script>
import { page } from '@/mixins/page'
export default {
  name: 'TableColumns',
  mixins: [page],
  props: {
    data: {
      type: Array,
      default: () => []
    },
    columns: {
      type: Array,
      default: () => []
    },
    //是否展示操作栏
    showOperator: {
      type: Boolean,
      default: false
    },
    //操作栏宽度
    operatorWidth: {
      type: Number,
      default: 100
    },
    //是否展示边框
    showBorder: {
      type: Boolean,
      default: true
    },
    //是否展示序列
    showIndex: {
      type: Boolean,
      default: false
    },
    //是否展示选择框
    showSelection: {
      type: Boolean,
      default: false
    },
    //自定义序列label
    indexLabel: {
      type: String,
      default: '序号'
    },
    height: {
      type: [String, Number],
      default: '100%'
    },
    maxHeight: {
      type: [String, Number],
      default: '100%'
    },
    stripe: {
      type: Boolean,
      default: true
    },
    //汇总数据
    totalRows: {
      type: Number,
      default: 0
    },
    // //分页页码
    // pageSizes: {
    //   type: Array,
    //   default: () => [10, 50, 100, 500],
    // },
    showPager: {
      type: Boolean,
      default: true
    },
    //是否固定操作栏
    fixedOperator: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      pageNum: 1,
      pageSize: 10,
      // totalRows: 0,
      list: []
    }
  },
  methods: {
    rowClick(row, column, event) {
      this.$emit('rowClick', row, column, event)
    },
    rowDblclick(row, column, event) {
      this.$emit('rowDblclick', row, column, event)
    },

    changeSize(pageSize) {
      this.pageSize = pageSize
      this.$emit('onSearch', { size: this.pageSize, current: this.pageNum })
    },
    handleCurrentChange(pageNum) {
      this.pageNum = pageNum
      this.$emit('onSearch', { size: this.pageSize, current: this.pageNum })
    },
    handleSelectionChange(selections) {
      this.$emit('selectionChange', selections)
    },
    sortChange({ column, prop, order }) {
      this.$emit('sortChange', { column, prop, order })
    }
  }
}
</script>
<style lang="scss" scoped>
@import '@/styles/components/table-layout.scss';
</style>
