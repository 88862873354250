<template>
  <section
    ref="cmain"
    class="c-main"
    :style="{ height: pageHeight }"
    @scroll="scrollThrottle()"
  >
    <div class="c-main__inner">
      <transition name="fade" mode="out-in">
        <keep-alive >
          <router-view v-if="$route.meta.cache"></router-view>
        </keep-alive>
      </transition>
      <transition name="fade" mode="out-in">
        <router-view v-if="!$route.meta.cache"></router-view>
      </transition>
    </div>
  </section>
</template>
<script>
import { mapActions } from "vuex";
import { throttle } from "throttle-debounce";
export default {
  name: "CMain",
  data() {
    return { pageHeight: "auto", redHeight: "" };
  },
  computed: {
    getCachedViews() {
      return this.$store.getters.getCacheViews;
    },
  },
  created() {
    this.scrollThrottle = throttle(300, this.mainScroll);
  },
  mounted() {
    this.computedHeight();
    window.addEventListener("resize", this.computedHeight);
  },
  methods: {
    ...mapActions({
      setTabsShadow: "tabsView/setTabsShadow",
    }),
    mainScroll() {
      const shadowStatus = this.$refs.cmain.scrollTop > 0;
      this.setTabsShadow(shadowStatus);
    },
    computedHeight() {
      const cHeader = document.getElementById("c-header");
      // const cTabs = document.getElementById("c-tabs");
      if (cHeader) {
        this.redHeight = cHeader.clientHeight;
        this.pageHeight =
          document.documentElement.clientHeight - this.redHeight + "px";
      } else {
        this.pageHeight = document.documentElement.clientHeight + "px";
      }
    },
  },
};
</script>
<style lang="scss" scoped>
@import "@/styles/index.scss";
.c-main {
  overflow-x: auto;
  overflow-y: auto;
  position: relative;
  width: 100%;
  background-color: $--color-base;
  .c-main__inner {
    display: block;
    min-width: 1215px;
    width: 100%;
    height: 100%;
    // 解决margin塌陷出现滚动条的问题
    border: 1px solid transparent;
  }
}
</style>