<template>
  <div class="base-sidebar">
    <ul>
      <div v-for="(item, i) in menus" :key="i">
        <li
          @click="executeRouter(item)"
          :class="activeMenu(item)"
          v-if="!item.hidden"
        >
          <!-- <c-icon
            :icon-class="item.icon"
            className="base-sidebar__icon"
          ></c-icon> -->
          <div class="base-sidebar__label">{{ item.label }}</div>
        </li>
      </div>
    </ul>
  </div>
</template>
<script>
export default {
  data() {
    return {
      menus: [
        {
          code: "home",
          children: [],
          rank: 0,
          id: 29810,
          menuIcon: "home",
          moduleId: 0,
          parentId: 29795,
          menuName: "首页",
          menuUrl: "views/base/home/index",
          accessPermissions: '["home"]',
          accessPermissionArr: ["home"],
          w3Comment: "",
          role: "home",
          display: true,
          icon: "home",
          component: "base/home/index",
          label: "首页",
          shortLabel: "首页",
          path: "home",
          name: "Home",
          nav: "NAV_DEFAULT",
          hidden: false,
          floor: 1,
          used: true,
        },
        {
          code: "task",
          children: [],
          rank: 1,
          id: 29936,
          menuIcon: "task",
          moduleId: 0,
          parentId: 29795,
          menuName: "任务",
          menuUrl: "views/base/task/index",
          accessPermissions: '["task"]',
          accessPermissionArr: ["task"],
          w3Comment: "",
          role: "task",
          display: true,
          icon: "task",
          component: "base/task/index",
          label: "任务",
          shortLabel: "任务",
          path: "task",
          name: "Task",
          nav: "NAV_DEFAULT",
          hidden: false,
          floor: 1,
          used: true,
        },
        {
          code: "data",
          children: [],
          rank: 2,
          id: 30044,
          menuIcon: "data",
          moduleId: 0,
          parentId: 29795,
          menuName: "数据",
          menuUrl: "http://bi.test.ztosys.com/#/business-monitor",
          accessPermissions: '["data"]',
          accessPermissionArr: ["data"],
          w3Comment: "",
          role: "data",
          display: true,
          icon: "data",
          component: "common/frame",
          iframe: "data",
          iframeUrl: "http://bi.test.ztosys.com/#/business-monitor",
          label: "数据",
          shortLabel: "数据",
          path: "data",
          name: "Data",
          nav: "NAV_DEFAULT",
          hidden: false,
          floor: 1,
          used: true,
        },
      ],
    };
  },
  methods: {
    executeRouter() {},
    activeMenu() {},
  },
};
</script>