//详情页面
export const LoaclRouterMap = [
  {
    path: 'setDistribution',
    name: 'setDistribution',
    component: () => import('@/views/Distribution/modules/SetDistribution.vue'),
    meta: {
      label: '分销管理新增',
      cache: false,
      closable: true,
    },
  },
  {
    path: 'commissionOrder',
    name: 'commissionOrder',
    component: () => import('@/views/Distribution/modules/CommissionOrder.vue'),
    meta: {
      label: '查看分销订单',
      cache: false,
      closable: true,
    },
  },
  {
    path: 'commissionUser',
    name: 'commissionUser',
    component: () => import('@/views/Distribution/modules/CommissionUser.vue'),
    meta: {
      label: '邀请记录',
      cache: false,
      closable: true,
    },
  },
  {
    path: 'storeDetail',
    name: 'storeDetail',
    component: () => import('@/views/Store/modules/Detail.vue'),
    meta: {
      label: '门店管理详情',
      cache: false,
      closable: true,
    },
  },
  {
    path: 'productDetail',
    name: 'productDetail',
    component: () => import('@/views/Product/modules/Detail.vue'),
    meta: {
      label: '商品详情',
      cache: false,
      closable: true,
    },
  },
  {
    path: 'orderDetail',
    name: 'orderDetail',
    component: () => import('@/views/Order/modules/OrderDetail.vue'),
    meta: {
      label: '订单管理',
      cache: false,
      closable: true,
    },
  },
  {
    path: 'couponDetail',
    name: 'couponDetail',
    component: () => import('@/views/Coupon/modules/AddDetail.vue'),
    meta: {
      label: '优惠券管理详情',
      cache: false,
      closable: true,
    },
  },
  {
    path: 'activityDetail',
    name: 'activityDetail',
    component: () => import('@/views/Activity/modules/Detail.vue'),
    meta: {
      label: '活动管理详情',
      cache: false,
      closable: true,
    },
  },
  {
    path: 'employeeOrder',
    name: 'employeeOrder',
    component: () =>
      import('@/views/SystemSetting/Employee/modules/OrderList/index.vue'),
    meta: {
      label: '员工订单',
      cache: false,
      closable: true,
    },
  },
  {
    path: 'rechargeConfig',
    name: 'rechargeConfig',
    component: () =>
      import('@/views/SystemSetting/RechargeConfig/index.vue'),
    meta: {
      label: '充值配置',
      cache: false,
      closable: true,
    },
  },
];
