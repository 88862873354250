// 域名
// https://hx.hnmyhx.cn/hx/order/export?orderNo&receiverPhone&startTime=2024-01-01%2012:12:12&endTime=2024-01-30%2012:12:12&buyerPhone
// https://hx.hnmyhx.cn/hx/order/export?size=10&current=1&startTime=2023-12-19%2000%3A00%3A00&endTime=2024-01-19%2023%3A59%3A59
const URL = {
  // 生产环境
  PROD: ['https://hx.hnmyhx.cn'],
  // 预发环境
  PRE: ['https://hx.hnmyhx.cn'],
  // 集成测试环境
  SIT: ['https://hx.hnmyhx.cn'],
  // 测试环境
  TEST: ['http://localhost'],
  //开发环境
  DEV: ['http://localhost'],
};

// 后台接口的域名
const API = {
  // 生产环境
  PROD: {
    HostName: 'https://hx.hnmyhx.cn',
  },
  // 预发环境
  PRE: {
    HostName: 'https://hx.hnmyhx.cn',
  },
  // 测试环境
  TEST: {
    HostName: 'https://hx.hnmyhx.cn',
  },
  // 开发环境
  DEV: {
    HostName: 'https://hx.hnmyhx.cn',
  },
};

// 后台接口的域名。如模板 HostName 为网关接口。
let HostName = API.TEST.HostName || '';
let Env = 'TEST';
function checkUrl(url) {
  return window.location.href.indexOf(url) === 0;
}

// 根据域名为HostName重新赋值
Object.keys(URL).some((env) => {
  const urls = URL[env];
  // console.log(checkUrl)
  if (urls.length && urls.some(checkUrl)) {
    HostName = API[env].HostName || '';
    Env = env;
    return true;
  }
  return false;
});

// console.log(HostName, Env)

// 本地mockLocal时，为空字符串
// HostName = GLOBAL.mockLocal ? '' : HostName

export { HostName, Env };
