const getters = {
  isCollapse: state => !state.pageConfig.sidebar.opened, // 计算相反属性
  permissions: state => state.user.userInfo.permissions, // 部分用到 store.getters.permissions
  getUserInfo: (state) => state.user.userInfo,
  getActiveMenuInfo: (state) => state.menu.activeMenuInfo,
  getPageConfig: (state) => state.pageConfig.pageInfo,
  getDefaultColor: (state) => state.pageConfig.defaultColor,
  isDefaultSkin: (state) => state.pageConfig.isDefaultSkin,
  getVisitedViews: state => {
    return [...state.tabsView.defaultViews, ...state.tabsView.visitedViews]
  },
  getMenus:state=>state.menu.menus
}
export default getters
