import utils from '@/utils/utils';
const Types = {
  SET_SKIN: 'SET_SKIN',
  SET_NAV_OPEN: 'SET_NAV_OPEN',
  TOGGLE_SIDEBAR: 'TOGGLE_SIDEBAR',
  CHANGE_SETTING: 'CHANGE_SETTING',
  SET_GUIDE_STATUS: 'SET_GUIDE_STATUS',
  SET_DEFAULT_COLOR: 'SET_DEFAULT_COLOR',
  SET_DEFAULT_HD_COLOR: 'SET_DEFAULT_HD_COLOR',
};
const defaultColor = window.localStorage.getItem('defaultSkin');
const isDefaultSkin = window.localStorage.getItem('isDefaultSkin');
const state = {
  //侧边栏
  sidebar: {
    opened: true,
  },
  // 开启导航菜单
  navOpen: true,
  defaultColor: defaultColor || '#007aff',
  isDefaultSkin: isDefaultSkin === 'true' ? true : false || false,
};

const actions = {
  //切换侧边栏
  toggleSideBar({ commit }) {
    commit(Types.TOGGLE_SIDEBAR);
  },
  // 切换开启导航菜单
  setNavOpen({ commit }, params) {
    return new Promise((resolve) => {
      commit(Types.SET_NAV_OPEN, params);
      resolve();
    });
  },
  setDefaultColor({ commit }, params) {
    commit(Types.SET_DEFAULT_COLOR, params);
  },
  setDefaultHdColor({ commit }, params) {
    commit(Types.SET_DEFAULT_HD_COLOR, params);
  },
};

const mutations = {
  [Types.TOGGLE_SIDEBAR]: (state) => {
    state.sidebar.opened = !state.sidebar.opened;
  },
  [Types.SET_NAV_OPEN]: (state) => {
    state.navOpen = !state.navOpen;
  },
  [Types.SET_DEFAULT_COLOR]: (state, color) => {
    state.defaultColor = color;
    utils.setStorage('defaultSkin', color);
  },
  [Types.SET_DEFAULT_HD_COLOR]: (state, isDefaultSkin) => {
    state.isDefaultSkin = isDefaultSkin;
    utils.setStorage('isDefaultSkin', isDefaultSkin);
  },
};
export default {
  namespaced: true,
  state,
  actions,
  mutations,
};
