<template>
  <el-checkbox-group v-model="inputValue">
    <el-checkbox
      :label="item.label"
      v-for="(item, index) in options"
      :key="index"
      >{{ item.label }}</el-checkbox
    >
  </el-checkbox-group>
</template>
<script>
export default {
  name: "HCheckbox",
  props: {
    value: {
      type: [Array],
    },
    options: {
      type: Array,
      default: () => [],
    },
    formData: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {};
  },
  computed: {
    inputValue() {
      return this.formData[this.$attrs.prop];
    },
  },
};
</script>
